import { clsx } from 'clsx';
import React, { useEffect, useState } from 'react';
import { UseFormSetValue } from 'react-hook-form';

import { FormItem } from '@/components/FormItem';
import { FormSelectAsync } from '@/components/FormSelectAsync';
import { Label } from '@/components/Label';

import { ICreateNotoficationForm } from '../validation';
import styles from './PersonalAccountSelect.module.css';
import { SelectedPA } from './SelectedPA';
import { usePaSearch } from './usePaSearch';

interface IPersonalAccountSelectProps {
  value: number;
  setValue: UseFormSetValue<ICreateNotoficationForm>;
  errorMessage?: string;
}

export const PersonalAccountSelect: React.FC<IPersonalAccountSelectProps> = (
  props,
) => {
  const { value, setValue, errorMessage } = props;
  const [selectedValue, setSelectedValue] = useState(1);
  const { selectedUsers, setSelectedUsers, debounceSearchOptions } =
    usePaSearch();

  useEffect(() => {
    setSelectedValue(value);

    if (value) {
      setSelectedUsers([]);
    }
  }, [setSelectedUsers, value]);

  useEffect(() => {
    if (selectedValue === 1) {
      setValue('personalAccounts', []);
      setValue('for_all', 1);
    } else {
      setValue('for_all', 0);
      setValue('personalAccounts', [...selectedUsers.map((user) => user.id)]);
    }
  }, [selectedUsers, selectedValue, setValue]);

  return (
    <>
      <div className={styles.root}>
        <div
          className={clsx(styles.tab, {
            [styles.isSelected]: selectedValue === 1,
          })}
          onClick={() => setSelectedValue(1)}
        >
          Уведомить всех
        </div>
        <div
          className={clsx(styles.tab, {
            [styles.isSelected]: selectedValue === 0,
          })}
          onClick={() => setSelectedValue(0)}
        >
          Выбрать лицевые счета
        </div>
      </div>

      {selectedValue === 0 && (
        <div>
          <SelectedPA
            selectedUsers={selectedUsers}
            setSelectedUsers={setSelectedUsers}
          />

          <FormItem errorMessage={errorMessage}>
            <Label mainText="Добавление лицевых счетов" />
            <FormSelectAsync
              value={null}
              maxMenuHeight={200}
              placeholder="Поиск"
              loadOptions={debounceSearchOptions}
              onChange={(value: any) => {
                const data = value;
                setSelectedUsers((prev) => [...prev, { ...data }]);
              }}
              fullWidth
            />
          </FormItem>
        </div>
      )}
    </>
  );
};

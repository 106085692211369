import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { useSnackbarError } from '@/hooks/useSnackbackError';
import { useReturnIntoOperatorMutation } from '@/redux/api/userApi';
import { clearUserInfo } from '@/redux/slices/user';

export const useReturnIntoOperator = (): (() => void) => {
  const [returnIntoOperator] = useReturnIntoOperatorMutation();
  const displayError = useSnackbarError();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onReturnInToOperator = () => {
    returnIntoOperator()
      .unwrap()
      .then((res) => {
        dispatch(clearUserInfo());
        localStorage.setItem('accessToken', res.access_token);

        navigate('/');
      })
      .catch(() => {
        displayError('Ошибка переключения аккаунтов');
      });
  };

  return onReturnInToOperator;
};

import { createApi } from '@reduxjs/toolkit/query/react';

import { baseQuery } from './baseQuery';

export const externalLinkApi = createApi({
  reducerPath: 'externalLinkApi',
  baseQuery: baseQuery,
  endpoints: (builder) => ({
    getPersonalAccountByGuid: builder.query<
      { personal_account: string },
      { code: string; guid: string }
    >({
      query: (data) => ({
        url: `pa/getByGUID/?code=${data.code}&guid=${data.guid}`,
        method: 'GET',
      }),
    }),
  }),
});

export const { useLazyGetPersonalAccountByGuidQuery } = externalLinkApi;

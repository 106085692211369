import { clsx } from 'clsx';
import React, { DetailedHTMLProps, HTMLAttributes, ReactNode } from 'react';

import { IconPhone } from '../Icons/IconPhone';
import styles from './CallButton.module.css';

interface ICallButtonProps
  extends DetailedHTMLProps<
    HTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  children?: ReactNode;
  className?: string;
}

export const CallButton: React.FC<ICallButtonProps> = (props) => {
  const { className, ...restProps } = props;

  return (
    <button {...restProps} className={clsx(styles.root, className)}>
      <IconPhone className={styles.icon} />
      Заказать звонок
    </button>
  );
};

import { clsx } from 'clsx';
import React from 'react';
import useToggle from 'react-use/lib/useToggle';

import { ENotificationColor } from '@/consts';
import { INotification } from '@/interfaces/notification';
import { useCssQuery } from '@/providers/MediaQuery';

import { IconArrow } from '../Icons/IconArrow';
import { IconWarning } from '../Icons/IconWarning';
import styles from './NotificationGlobal.module.css';

interface INotificationGlobalProps {
  notification: INotification;
}

export const NotificationGlobal: React.FC<INotificationGlobalProps> = (
  props,
) => {
  const { isMobile } = useCssQuery();
  const { notification } = props;
  const [showDetails, toggleShowDetails] = useToggle(false);

  return (
    <div
      className={clsx(styles.root, {
        [styles.red]: notification.color === ENotificationColor.Red,
        [styles.green]: notification.color === ENotificationColor.Green,
        [styles.yellow]: notification.color === ENotificationColor.Yellow,
        [styles.isMobile]: isMobile,
      })}
    >
      <div className={styles.headingRow}>
        <div className={styles.message}>
          <div className={styles.iconWrapper}>
            <IconWarning className={styles.icon} />
          </div>

          {notification.title}
        </div>

        <div className={styles.moreDetailed} onClick={toggleShowDetails}>
          <IconArrow
            className={clsx(styles.spoilerIcon, {
              [styles.up]: showDetails,
            })}
          />
        </div>
      </div>

      {showDetails && (
        <div className={styles.description}>{notification.description}</div>
      )}
    </div>
  );
};

import React from 'react';
import { useSelector } from 'react-redux';

import { EUserRole } from '@/consts';
import { RootState } from '@/redux';

import { NavigationAdmin } from './Admin';
import { NavigationCustomer } from './Customer';

interface INavigationProps {
  toggleShowMobileMenu?: () => void;
}

export const Navigation: React.FC<INavigationProps> = (props) => {
  const { toggleShowMobileMenu } = props;
  const { user } = useSelector((state: RootState) => state);

  if (user.data?.user_role === EUserRole.Customer) {
    return <NavigationCustomer toggleShowMobileMenu={toggleShowMobileMenu} />;
  }

  return <NavigationAdmin toggleShowMobileMenu={toggleShowMobileMenu} />;
};

import React, { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';

const RequestsPage = lazy(() => import('@/routes/customer/Requests'));
const RequestCreatingPage = lazy(
  () => import('@/routes/customer/Requests/Creating'),
);
const RequestDetailsPage = lazy(
  () => import('@/routes/customer/Requests/Details'),
);

const RequestsRote: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<RequestsPage />} />
      <Route path="creating" element={<RequestCreatingPage />} />
      <Route
        path="request-details/:requestId"
        element={<RequestDetailsPage />}
      />
    </Routes>
  );
};

export default RequestsRote;

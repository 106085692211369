import { createApi } from '@reduxjs/toolkit/query/react';

import {
  IRequestCategoryInfo,
  IRequestCreateRequest,
  IRequestCreateResponse,
  IRequestDetailsRequest,
  IRequestDetailsResponse,
  IRequestListRequest,
  IRequestListResponse,
} from '@/interfaces/requests';

import { baseQueryWithReauth } from './baseQuery';

export const requestsApi = createApi({
  reducerPath: 'requestsApi',
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getRequestList: builder.query<IRequestListResponse, IRequestListRequest>({
      query: (data) => ({
        url: `requests/get?per_page=10&page=${data.page}`,
        method: 'GET',
      }),
    }),
    getRequestDetails: builder.query<
      IRequestDetailsResponse,
      IRequestDetailsRequest
    >({
      query: (data) => ({
        url: `requests/getDetail/${data.id}`,
        method: 'GET',
      }),
    }),
    createRequest: builder.mutation<
      IRequestCreateResponse,
      IRequestCreateRequest
    >({
      query: (data) => {
        const formData = new FormData();
        formData.append('title', data.title);
        formData.append('category_id', String(data.category_id));

        if (data?.description) {
          formData.append('description', data.description);
        }

        data?.files?.map((file: File) => {
          formData.append('files[]', file);
        });

        return {
          url: 'requests/store',
          method: 'POST',
          body: formData,
        };
      },
    }),
    getRequestCategory: builder.query<Array<IRequestCategoryInfo>, void>({
      query: () => ({
        url: `requests/getCategories`,
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useGetRequestListQuery,
  useGetRequestDetailsQuery,
  useCreateRequestMutation,
  useGetRequestCategoryQuery,
} = requestsApi;

import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useState,
} from 'react';

interface ISwitchingPersonalAccountCtx {
  switchInProcess: boolean;
  setSwitchInProcess: React.Dispatch<React.SetStateAction<boolean>>;
}

const SwitchingPersonalAccountContext =
  createContext<ISwitchingPersonalAccountCtx>({
    switchInProcess: false,
    setSwitchInProcess: () => ({}),
  });

export const SwitchingPersonalAccountProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const [switchInProcess, setSwitchInProcess] = useState(false);

  return (
    <SwitchingPersonalAccountContext.Provider
      value={{ switchInProcess, setSwitchInProcess }}
    >
      {children}
    </SwitchingPersonalAccountContext.Provider>
  );
};

export const useSwitchPersonalAccontInProcess = () =>
  useContext(SwitchingPersonalAccountContext);

import { createApi } from '@reduxjs/toolkit/query/react';

import {
  IAccrualsHistoryDetailsRequest,
  IAccrualsHistoryDetailsResponse,
  IAccrualsHistoryRequest,
  IAccrualsHistoryResponse,
  ILastReceipt,
  ISendReceiptRequest,
} from '@/interfaces/accruals';

import { baseQueryWithReauth } from './baseQuery';

export const accrualsApi = createApi({
  reducerPath: 'accrualsApi',
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getAccrualsHistory: builder.query<
      IAccrualsHistoryResponse,
      IAccrualsHistoryRequest
    >({
      query: (data) => {
        const monthParam = data.filter?.month
          ? `&filter[month]=${data.filter.month}`
          : '';

        const yearParam = data.filter?.year
          ? `&filter[year]=${data.filter.year}`
          : '';

        const url = `charge-history/get?per_page=${data.per_page}&page=${data.page}
        ${monthParam}
        ${yearParam}`;

        return {
          url,
          method: 'GET',
        };
      },
    }),
    getAccrualsHistoryDetails: builder.query<
      IAccrualsHistoryDetailsResponse,
      IAccrualsHistoryDetailsRequest
    >({
      query: (data) => {
        const url = `charge-history/getDetail?period=${data.period}`;

        return {
          url,
          method: 'GET',
        };
      },
    }),
    sendReceipt: builder.mutation<{ message: string }, ISendReceiptRequest>({
      query: (data) => {
        const url = `requests/requestReceipt`;

        return {
          url,
          method: 'POST',
          body: data,
        };
      },
    }),
    getLastReceipt: builder.query<ILastReceipt, void>({
      query: () => ({
        url: `charge-history/getLastReceipt`,
        method: 'GET',
      }),
    }),

    downloadReceipt: builder.query<string, { receiptId: string }>({
      query: (data) => ({
        url: `files/download/${data.receiptId}`,
        method: 'GET',
        responseHandler: async (response) => {
          const result = await response.blob();

          const url = window.URL || window.webkitURL;
          const fileUrl = url.createObjectURL(result);

          return fileUrl;
        },
      }),
    }),
  }),
});

export const {
  useGetAccrualsHistoryQuery,
  useGetAccrualsHistoryDetailsQuery,
  useSendReceiptMutation,
  useGetLastReceiptQuery,
  useLazyDownloadReceiptQuery,
} = accrualsApi;

import { clsx } from 'clsx';
import { bindTrigger, usePopupState } from 'material-ui-popup-state/hooks';
import React from 'react';
import { useSelector } from 'react-redux';

import { IconNotification } from '@/components/Icons/IconNotification';
import { useCssQuery } from '@/providers/MediaQuery';
import { RootState } from '@/redux';

import styles from './NotificationBlock.module.css';
import { NotificationPopover } from './Popover';

export const NotificationBlock: React.FC = () => {
  const { isMobile } = useCssQuery();

  const {
    notifications: { list, countUnreadMessages },
  } = useSelector((state: RootState) => state);

  const slicedList = list
    .filter((notification) => !notification.is_read)
    .slice(0, 3);

  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'demoPopover',
  });

  return (
    <>
      <div
        className={clsx(styles.root, {
          [styles.isMobile]: isMobile,
        })}
        {...bindTrigger(popupState)}
      >
        <IconNotification />
        {countUnreadMessages > 0 && (
          <div className={styles.counter}>{countUnreadMessages}</div>
        )}
      </div>

      <NotificationPopover
        popupState={popupState}
        notificationList={slicedList}
        countUnreadMessages={countUnreadMessages}
      />
    </>
  );
};

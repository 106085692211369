import React from 'react';

import { IconPaymentHistory } from '@/components/Icons/IconPaymentHistory';
import { IconAccruals } from '@/components/Icons/sidebar/IconAccruals';
import { IconIndications } from '@/components/Icons/sidebar/IconIndications';
import { IconMyData } from '@/components/Icons/sidebar/IconMyData';
import { IconPayInfo } from '@/components/Icons/sidebar/IconPayInfo';
import { IconPersonalAccounts } from '@/components/Icons/sidebar/IconPersonalAccounts';
import { IconRequest } from '@/components/Icons/sidebar/IconRequest';
import { IconSupplier } from '@/components/Icons/sidebar/IconSupplier';

import { NavigationLink } from '../Link';
import styles from './Customer.module.css';

interface INavigationCustomerProps {
  toggleShowMobileMenu?: () => void;
}

export const NavigationCustomer: React.FC<INavigationCustomerProps> = (
  props,
) => {
  const { toggleShowMobileMenu } = props;

  return (
    <>
      <div className={styles.root}>
        <NavigationLink
          title="Показания"
          to="/"
          icon={<IconIndications />}
          toggleShowMobileMenu={toggleShowMobileMenu}
        />

        <NavigationLink
          title="Начисления"
          to="/accruals"
          icon={<IconAccruals />}
          toggleShowMobileMenu={toggleShowMobileMenu}
        />

        <NavigationLink
          title="История оплат"
          to="/payment-history"
          icon={<IconPaymentHistory />}
          toggleShowMobileMenu={toggleShowMobileMenu}
        />

        <NavigationLink
          title="Мои данные"
          to="/profile"
          icon={<IconMyData />}
          toggleShowMobileMenu={toggleShowMobileMenu}
        />

        <NavigationLink
          title="Поставщик"
          to="/supplier"
          icon={<IconSupplier />}
          toggleShowMobileMenu={toggleShowMobileMenu}
        />

        <NavigationLink
          title="Заявки"
          to="/requests"
          icon={<IconRequest />}
          toggleShowMobileMenu={toggleShowMobileMenu}
        />

        <NavigationLink
          title="Лицевые счета"
          to="/personal-accounts"
          icon={<IconPersonalAccounts />}
          toggleShowMobileMenu={toggleShowMobileMenu}
        />
      </div>

      <NavigationLink
        title="Как оплатить"
        to="/payment-info"
        icon={<IconPayInfo />}
        toggleShowMobileMenu={toggleShowMobileMenu}
      />
    </>
  );
};

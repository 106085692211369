import React from 'react';

interface Props {
  className?: string;
}

export const IconSnackbarError: React.FC<Props> = ({ className }) => {
  return (
    <svg
      className={className}
      width="29"
      height="30"
      viewBox="0 0 29 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M27.8219 15.0001C27.8219 7.64263 21.8574 1.67822 14.5 1.67822C7.14251 1.67822 1.1781 7.64263 1.1781 15.0001C1.1781 22.3576 7.14251 28.322 14.5 28.322C21.8574 28.322 27.8219 22.3576 27.8219 15.0001Z"
        stroke="#E73A5F"
        strokeWidth="1.8"
        strokeMiterlimit="10"
      />
      <path
        d="M9.96863 10.4688L19.0311 19.5312"
        stroke="#E73A5F"
        strokeWidth="1.8"
        strokeLinecap="round"
      />
      <path
        d="M19.0312 10.4688L9.96875 19.5312"
        stroke="#E73A5F"
        strokeWidth="1.8"
        strokeLinecap="round"
      />
    </svg>
  );
};

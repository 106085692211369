import { Popover } from '@mui/material';
import { bindPopover } from 'material-ui-popup-state';
import { PopupState } from 'material-ui-popup-state/hooks';
import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { IconPlus } from '@/components/Icons/IconPlus';
import { IconExit } from '@/components/Icons/sidebar/IconExit';
import { EUserRole } from '@/consts';
import { useLogout } from '@/hooks/useLogout';
import { RootState } from '@/redux';

import { useAvailablePersonalAccount } from '../../useAvailablePersonalAccount';
import styles from './ProfilePopover.module.css';
import { useSwitchPersonalAccount } from './useSwitchAccount';

interface IProfilePopover {
  popupState: PopupState;
}

export const ProfilePopover: React.FC<IProfilePopover> = ({ popupState }) => {
  const { user } = useSelector((state: RootState) => state);
  const { onSwitchPersonalAccount } = useSwitchPersonalAccount({ popupState });
  const onLogoutProfile = useLogout();
  const navigate = useNavigate();

  const personalAccountForSwitch = useAvailablePersonalAccount();

  const addNewPersonalAccount = () => {
    navigate('/personal-accounts/add');
  };

  return (
    <Popover
      {...bindPopover(popupState)}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      classes={{
        paper: styles.root,
      }}
    >
      <div className={styles.content}>
        {user.data?.user_role === EUserRole.Customer && (
          <button className={styles.addButton} onClick={addNewPersonalAccount}>
            <div className={styles.addButtonIcon}>
              <IconPlus className={styles.addIcon} />
            </div>
            Добавить лицевой счет
          </button>
        )}

        {user.data?.user_role === EUserRole.Customer && (
          <div className={styles.popoverData}>
            {personalAccountForSwitch?.map((personalAccount) => {
              return (
                <button
                  key={personalAccount.id}
                  className={styles.personalAccountButton}
                  onClick={() => onSwitchPersonalAccount(personalAccount.id)}
                >
                  <div>
                    Лицевой счет
                    <span className={styles.popoverDataNumber}>
                      {personalAccount.account_number}
                    </span>
                  </div>

                  <div className={styles.popoverAddress}>
                    {personalAccount.address}
                  </div>
                </button>
              );
            })}
          </div>
        )}

        <button className={styles.logoutButton} onClick={onLogoutProfile}>
          Выход
          <IconExit className={styles.logoutIcon} />
        </button>
      </div>
    </Popover>
  );
};

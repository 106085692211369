import React from 'react';
import { useNavigate } from 'react-router-dom';

import { MainButton } from '../MainButton';
import styles from './UnavailablePersonalAccount.module.css';

export const UnavailablePersonalAccount: React.FC = () => {
  const navigate = useNavigate();

  const addNewPersonalAccount = () => {
    navigate('/personal-accounts/add');
  };

  return (
    <div className={styles.root}>
      <span className={styles.text}>
        На данный момент у вас нет подлюченных лицевых счетов
      </span>

      <MainButton
        onClick={addNewPersonalAccount}
        className={styles.addNewAccountButton}
      >
        + Добавить лицевой счет
      </MainButton>
    </div>
  );
};

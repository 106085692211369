import { createApi } from '@reduxjs/toolkit/query/react';

import {
  IAddPersonalAccountRequest,
  IAddPersonalAccountResponse,
  IChangePersonalAccountRequest,
  IChangePersonalAccountResponse,
  IChangeReceiptTypeRequest,
  IChangeReceiptTypeResponse,
  IPersonalAccountDetailed,
  IPersonalAccountDetailesRequest,
  IUnpinPersonalAccountRequest,
  IUnpinPersonalAccountResponse,
} from '@/interfaces/personalAccounts';

import { baseQueryWithReauth } from './baseQuery';

export const personalAccountsApi = createApi({
  reducerPath: 'personalAccountsApi',
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    addPersonalAccount: builder.mutation<
      IAddPersonalAccountResponse,
      IAddPersonalAccountRequest
    >({
      query: (data) => ({
        url: 'pa/connect',
        method: 'POST',
        body: data,
      }),
    }),
    changePersonalAccount: builder.mutation<
      IChangePersonalAccountResponse,
      IChangePersonalAccountRequest
    >({
      query: (data) => ({
        url: 'user/change-account',
        method: 'POST',
        body: data,
      }),
    }),
    unpinPersonalAccount: builder.mutation<
      IUnpinPersonalAccountResponse,
      IUnpinPersonalAccountRequest
    >({
      query: (data) => ({
        url: `pa/delete/${data.personalAccountId}`,
        method: 'DELETE',
      }),
    }),
    getPeronalAccountDetailes: builder.query<
      IPersonalAccountDetailed,
      IPersonalAccountDetailesRequest
    >({
      query: (data) => ({
        url: `pa/get/${data.personalAccountId}`,
        method: 'GET',
      }),
    }),
    changeReceiptType: builder.mutation<
      IChangeReceiptTypeResponse,
      IChangeReceiptTypeRequest
    >({
      query: (data) => ({
        url: `pa/changeReceiptType`,
        method: 'POST',
        body: data,
      }),
    }),
    getPersonalAccountByGuid: builder.query<
      { personal_account: string },
      { code: string; guid: string }
    >({
      query: (data) => ({
        url: `pa/getByGUID/?code=${data.code}&guid=${data.guid}}`,
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useAddPersonalAccountMutation,
  useChangePersonalAccountMutation,
  useUnpinPersonalAccountMutation,
  useGetPeronalAccountDetailesQuery,
  useChangeReceiptTypeMutation,
  useGetPersonalAccountByGuidQuery,
} = personalAccountsApi;

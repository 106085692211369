import React from 'react';

interface Props {
  className?: string;
}

export const IconAttention: React.FC<Props> = ({ className }) => {
  return (
    <svg
      className={className}
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 15.5938C11.5387 15.5938 11.1562 15.2112 11.1562 14.75V9.125C11.1562 8.66375 11.5387 8.28125 12 8.28125C12.4612 8.28125 12.8438 8.66375 12.8438 9.125V14.75C12.8438 15.2112 12.4612 15.5938 12 15.5938Z"
        fill="#E73A5F"
      />
      <path
        d="M12 19.2499C11.9325 19.2499 11.8538 19.2386 11.775 19.2274C11.7075 19.2161 11.64 19.1936 11.5725 19.1599C11.505 19.1374 11.4375 19.1036 11.37 19.0586C11.3137 19.0136 11.2575 18.9686 11.2012 18.9236C10.9987 18.7099 10.875 18.4174 10.875 18.1249C10.875 17.8324 10.9987 17.5399 11.2012 17.3261C11.2575 17.2811 11.3137 17.2361 11.37 17.1911C11.4375 17.1461 11.505 17.1124 11.5725 17.0899C11.64 17.0561 11.7075 17.0336 11.775 17.0224C11.9213 16.9886 12.0787 16.9886 12.2137 17.0224C12.2925 17.0336 12.36 17.0561 12.4275 17.0899C12.495 17.1124 12.5625 17.1461 12.63 17.1911C12.6863 17.2361 12.7425 17.2811 12.7988 17.3261C13.0013 17.5399 13.125 17.8324 13.125 18.1249C13.125 18.4174 13.0013 18.7099 12.7988 18.9236C12.7425 18.9686 12.6863 19.0136 12.63 19.0586C12.5625 19.1036 12.495 19.1374 12.4275 19.1599C12.36 19.1936 12.2925 19.2161 12.2137 19.2274C12.1462 19.2386 12.0675 19.2499 12 19.2499Z"
        fill="#E73A5F"
      />
      <path
        d="M18.7571 24.5H5.23832C3.06327 24.5 1.40131 23.6614 0.553603 22.1496C-0.282953 20.6378 -0.171412 18.689 0.888226 16.6693L7.6476 3.79528C8.76301 1.66929 10.3023 0.5 11.9977 0.5C13.6931 0.5 15.2324 1.66929 16.3478 3.79528L23.1072 16.6811C24.1668 18.7008 24.2895 20.6378 23.4418 22.1614C22.5941 23.6614 20.9321 24.5 18.7571 24.5ZM11.9977 2.27165C10.9492 2.27165 9.92303 3.12205 9.10879 4.65748L2.36056 17.5433C1.60209 18.9843 1.47939 20.3071 2.00363 21.2638C2.52788 22.2205 3.6879 22.7402 5.24947 22.7402H18.7682C20.3298 22.7402 21.4787 22.2205 22.0141 21.2638C22.5495 20.3071 22.4156 18.9961 21.6571 17.5433L14.8866 4.65748C14.0724 3.12205 13.0462 2.27165 11.9977 2.27165Z"
        fill="#E73A5F"
      />
    </svg>
  );
};

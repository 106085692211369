import { useSnackbar } from 'notistack';
import { useCallback } from 'react';

export const useSnackbarSuccess = () => {
  const { enqueueSnackbar } = useSnackbar();

  return useCallback(
    (message: string) => {
      enqueueSnackbar(message, { variant: 'success' });
    },
    [enqueueSnackbar],
  );
};

import { createApi } from '@reduxjs/toolkit/query/react';

import { INotification, INotificationData } from '@/interfaces/notification';

import { baseQueryWithReauth } from './baseQuery';

export const notificationsApi = createApi({
  reducerPath: 'notificationsApi',
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getNotifications: builder.query<
      INotificationData,
      { isRead: number; page: number }
    >({
      query: (data) => ({
        url: `notifications/get?&per_page=10&is_read=${data.isRead}&page=${data.page}`,
        method: 'GET',
      }),
    }),
    getGlobalNotifications: builder.query<Array<INotification>, void>({
      query: () => ({
        url: `notifications/getGlobal`,
        method: 'GET',
      }),
    }),
    markRead: builder.mutation<void, Array<number>>({
      query: (data) => ({
        url: 'notifications/read',
        method: 'POST',
        body: {
          ids: data,
        },
      }),
    }),
    markReadAll: builder.mutation<void, void>({
      query: () => ({
        url: 'notifications/readAll',
        method: 'POST',
      }),
    }),
  }),
});

export const {
  useLazyGetNotificationsQuery,
  useMarkReadAllMutation,
  useMarkReadMutation,
  useLazyGetGlobalNotificationsQuery,
} = notificationsApi;

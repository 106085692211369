import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import { Resolver, useForm } from 'react-hook-form';

import { useSnackbarSuccess } from '@/hooks/useSnackbatSuccess';
import { EServerMessageType, IServerMessage } from '@/interfaces/common';
import { useSendCallRequestMutation } from '@/redux/api/callRequestApi';

import { IRequestCallForm, requestCallFormShema } from './validation';

export default function useRequestCall(onClose: () => void) {
  const [sendCallRequest, { isLoading }] = useSendCallRequestMutation();
  const [serverMessage, setServerMessage] = useState<IServerMessage | null>(
    null,
  );
  const onSuccess = useSnackbarSuccess();

  const form = useForm<IRequestCallForm>({
    reValidateMode: 'onSubmit',
    resolver: yupResolver(requestCallFormShema) as Resolver<IRequestCallForm>,
  });

  const onSubmit = (data: IRequestCallForm) => {
    sendCallRequest({ ...data })
      .unwrap()
      .then(() => {
        onSuccess('Заявка на звонок успешно отправлена');
        onClose();
      })
      .catch((error) => {
        setServerMessage({
          type: EServerMessageType.Error,
          text: error.data.message,
        });
      });
  };

  return {
    ...form,
    handleSubmit: form.handleSubmit(onSubmit),
    isLoading,
    serverMessage,
  };
}

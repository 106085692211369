import React from 'react';

import styles from './NotFoundPage.module.css';

export const NotFoundPage: React.FC = () => {
  return (
    <div className={styles.root}>
      <p className={styles.text}>Такой страницы не существует</p>
    </div>
  );
};

import React from 'react';

interface Props {
  className?: string;
}

export const IconPayInfo: React.FC<Props> = ({ className }) => {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.900024"
        y="4.5"
        width="22.2"
        height="15"
        rx="1.5"
        stroke="#828282"
        strokeWidth="1.5"
      />
      <path
        d="M3 16.5H6"
        stroke="#828282"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M9 16.5H13.5"
        stroke="#828282"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <rect
        x="2.25"
        y="8.25"
        width="19.5"
        height="1.5"
        fill="#828282"
        stroke="#828282"
        strokeWidth="1.5"
      />
    </svg>
  );
};

import React, { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';

import { EUserRole } from '@/consts';

import CounterpartiesRoute from './admin/Counterparties/CounterpartiesRoute';
import OperatorsRoute from './admin/Operators/OperatorsRoute';
import RequestsAdminRoute from './admin/Requests/RequestsRoute';
import { PrivateRoute } from './PrivateRoute';
const StatisticsPage = lazy(() => import('@/routes/admin/Statistics'));
const SettingsPage = lazy(() => import('@/routes/admin/Settings'));
const AgreementPage = lazy(() => import('@/routes/admin/Agreement'));
const NotificationsPage = lazy(() => import('@/routes/customer/Notifications'));

const AdminRouter: React.FC = () => {
  return (
    <Routes>
      <Route path="/*" element={<RequestsAdminRoute />} />
      <Route path="/statistics" element={<StatisticsPage />} />
      <Route path="/settings" element={<SettingsPage />} />
      <Route path="/counterparties/*" element={<CounterpartiesRoute />} />
      <Route
        path="/operators/*"
        element={
          <PrivateRoute roles={[EUserRole.Admin]}>
            <OperatorsRoute />
          </PrivateRoute>
        }
      />
      <Route path="/agreement" element={<AgreementPage />} />
      <Route path="/notifications" element={<NotificationsPage />} />
    </Routes>
  );
};

export default AdminRouter;

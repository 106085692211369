import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { FormControlLabel } from '@mui/material';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import React, { useEffect, useState } from 'react';
import { UseFormSetValue } from 'react-hook-form';

import { FormItem } from '@/components/FormItem';
import { Label } from '@/components/Label';

import { ICreateNotoficationForm } from '../validation';

interface IColorSelectProps {
  setValue: UseFormSetValue<ICreateNotoficationForm>;
}

export const ColorSelect: React.FC<IColorSelectProps> = (props) => {
  const { setValue } = props;
  const [colorValue, setColorValue] = useState(1);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setColorValue(Number(event.target.value));
  };

  useEffect(() => {
    setValue('color', colorValue);
  }, [colorValue, setValue]);

  return (
    <div>
      <FormItem>
        <Label mainText="Цвет уведомления" />
        <RadioGroup
          aria-labelledby="color-group"
          name="color-group"
          value={colorValue}
          onChange={handleChange}
          sx={{ gap: 2, flexWrap: 'wrap', flexDirection: 'row' }}
        >
          <FormControlLabel
            value="1"
            control={
              <Radio
                sx={{
                  color: '#FF314B',
                  '&.Mui-checked': {
                    color: '#FF314B',
                  },
                }}
                checkedIcon={<CheckCircleIcon />}
              />
            }
            label="Красный"
          />
          <FormControlLabel
            value="2"
            control={
              <Radio
                sx={{
                  color: '#00DC5C',
                  '&.Mui-checked': {
                    color: '#00DC5C',
                  },
                }}
                checkedIcon={<CheckCircleIcon />}
              />
            }
            label="Зеленый"
          />
          <FormControlLabel
            value="3"
            control={
              <Radio
                sx={{
                  color: '#FAAD14',
                  '&.Mui-checked': {
                    color: '#FAAD14',
                  },
                }}
                checkedIcon={<CheckCircleIcon />}
              />
            }
            label="Желтый"
          />
        </RadioGroup>
      </FormItem>
    </div>
  );
};

import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

export interface GlobalErrorState {
  isError: boolean;
  isServerError: boolean;
}

const initialState: GlobalErrorState = {
  isError: false,
  isServerError: false,
};

export const globalErrorSlice = createSlice({
  name: 'globalError',
  initialState,
  reducers: {
    updateError: (state, action: PayloadAction<{ isError: boolean }>) => {
      state.isError = action.payload.isError;
    },
    updateServerError: (
      state,
      action: PayloadAction<{ isServerError: boolean }>,
    ) => {
      state.isServerError = action.payload.isServerError;
    },
  },
});

export const { updateError, updateServerError } = globalErrorSlice.actions;

export default globalErrorSlice.reducer;

import { clsx } from 'clsx';
import React from 'react';

import { useSwitchPersonalAccount } from '../../Profile/Popover/useSwitchAccount';
import styles from './AccountRow.module.css';

interface IAccountRowProps {
  title: string;
  personalAccountId?: number;
  data?: number | string;
  address?: string;
  isSelected?: true;
  toggleOpen?: () => void;
}

export const AccountRow: React.FC<IAccountRowProps> = (props) => {
  const { title, personalAccountId, data, address, isSelected, toggleOpen } =
    props;

  const { onSwitchPersonalAccount } = useSwitchPersonalAccount({ toggleOpen });

  return (
    <div
      className={clsx(styles.root, {
        [styles.selected]: isSelected,
      })}
      onClick={() => {
        if (personalAccountId) {
          onSwitchPersonalAccount(personalAccountId);
        }
      }}
    >
      <div>
        <span className={styles.title}>{title}</span>
        <span className={styles.data}>{data}</span>
      </div>

      <div className={styles.address}>{address}</div>
    </div>
  );
};

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import Loading from '@/components/Loading';
import { SwitchingPersonalAccountProvider } from '@/providers/SwithingPersonalAccount';
import { RootState } from '@/redux';
import {
  useLazyGetGlobalNotificationsQuery,
  useLazyGetNotificationsQuery,
} from '@/redux/api/notificationsApi';
import { useLazyGetUserProfileQuery } from '@/redux/api/userApi';
import {
  updateGlobalNotificationList,
  updateNotificationList,
} from '@/redux/slices/notifications';
import { updateUserInfo } from '@/redux/slices/user';
import UserRouter from '@/routes/UserRouter';

import { UserLayout } from '../UserLayout';

export const MainLayout: React.FC = () => {
  const {
    user: { data, isAuthorized },
    globalError,
  } = useSelector((state: RootState) => state);

  const { pathname, search } = useLocation();
  const [getUserProfile] = useLazyGetUserProfileQuery();
  const [getNotifications] = useLazyGetNotificationsQuery();
  const [getGlobalNotification] = useLazyGetGlobalNotificationsQuery();
  const dispatch = useDispatch();
  const currentPersonalAccount = data?.current_account;
  const navigate = useNavigate();
  const accessToken = localStorage.getItem('accessToken');

  useEffect(() => {
    if (accessToken) {
      getUserProfile()
        .unwrap()
        .then((res) => {
          dispatch(
            updateUserInfo({
              isAuthorized: true,
              data: { ...res },
            }),
          );
        })
        .catch(() => {
          navigate('/auth');
        });
    } else {
      if (!globalError.isError) {
        navigate('/auth');
      }

      if (pathname === '/receipt-link') {
        navigate(`auth/receipt-link${search}`);
      }
    }
  }, [accessToken]);

  useEffect(() => {
    if (isAuthorized && currentPersonalAccount) {
      getNotifications({ isRead: 0, page: 1 })
        .unwrap()
        .then((res) => {
          dispatch(
            updateNotificationList({
              list: res.data,
              total: res.total,
              isShowAllMessages: false,
              countUnreadMessages: res.unread_count,
            }),
          );
        })
        .catch((error) => {
          console.log(error);
        });

      getGlobalNotification()
        .unwrap()
        .then((res) => {
          dispatch(updateGlobalNotificationList({ globalList: res }));
        });
    }
  }, [
    dispatch,
    getNotifications,
    isAuthorized,
    currentPersonalAccount,
    getGlobalNotification,
  ]);

  if (isAuthorized) {
    return (
      <SwitchingPersonalAccountProvider>
        <UserLayout>
          <React.Suspense
            fallback={<Loading title="Идет загрузка" variant="internal" />}
          >
            <UserRouter />
          </React.Suspense>
        </UserLayout>
      </SwitchingPersonalAccountProvider>
    );
  }

  return <Loading title="Идет загрузка" variant="internal" maxHeight />;
};

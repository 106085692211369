import React, { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';

const RequestsInWorkPage = lazy(() => import('@/routes/admin/Requests/InWork'));
const RequestsInWorkDetailsPage = lazy(
  () => import('@/routes/admin/Requests/InWork/Details'),
);

const RequestsInWorkRoute: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<RequestsInWorkPage />} />
      <Route
        path="details/:requestId"
        element={<RequestsInWorkDetailsPage />}
      />
    </Routes>
  );
};

export default RequestsInWorkRoute;

import React from 'react';

interface Props {
  className?: string;
}

export const IconPlus: React.FC<Props> = ({ className }) => {
  return (
    <svg
      className={className}
      width="12"
      height="12"
      viewBox="0 0 12 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.12 0.92H5.128V5.888H0.112V7.784H5.128V12.752H7.12V7.784H12.136V5.888H7.12V0.92Z"
        fill="#1251A0"
      />
    </svg>
  );
};

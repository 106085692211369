import { createApi } from '@reduxjs/toolkit/query/react';

import { IOrganization } from '@/interfaces/organization';

import { baseQueryWithReauth } from './baseQuery';

export const organizationsApi = createApi({
  reducerPath: 'organizationsApi',
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getOrganization: builder.query<IOrganization, void>({
      query: () => ({
        url: 'organisation/get',
        method: 'GET',
      }),
    }),
  }),
});

export const { useGetOrganizationQuery } = organizationsApi;

import React, { createContext, PropsWithChildren, useContext } from 'react';

import { useLoginIntoCustomer } from './useLoginIntoCustomer';
import { useReturnIntoOperator } from './useReturnIntoOperator';

interface ISwitchAccountCtx {
  onLoginInToCustomer: (customerId: number) => void;
  onReturnInToOperator: () => void;
}

const SwitchAccountContext = createContext<ISwitchAccountCtx>({
  onLoginInToCustomer: () => ({}),
  onReturnInToOperator: () => ({}),
});

export const SwitchAccountProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const onLoginInToCustomer = useLoginIntoCustomer();
  const onReturnInToOperator = useReturnIntoOperator();

  return (
    <SwitchAccountContext.Provider
      value={{
        onLoginInToCustomer,
        onReturnInToOperator,
      }}
    >
      {children}
    </SwitchAccountContext.Provider>
  );
};

export const useSwitchAccount = () => useContext(SwitchAccountContext);

import { Popover } from '@mui/material';
import { bindPopover, PopupState } from 'material-ui-popup-state/hooks';
import React from 'react';

import { INotification } from '@/interfaces/notification';

import { NotificationBlockMessageList } from './NotificationBlockMessageList';
import styles from './NotificationPopover.module.css';

interface INotificationPopoverProps {
  popupState: PopupState;
  notificationList: Array<INotification>;
  countUnreadMessages: number;
}

export const NotificationPopover: React.FC<INotificationPopoverProps> = (
  props,
) => {
  const { popupState, notificationList, countUnreadMessages } = props;

  return (
    <Popover
      {...bindPopover(popupState)}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      className={styles.root}
    >
      <NotificationBlockMessageList
        popupState={popupState}
        notificationList={notificationList}
        countUnreadMessages={countUnreadMessages}
      />
    </Popover>
  );
};

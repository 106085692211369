import React from 'react';

interface Props {
  className?: string;
}

export const IconMenu: React.FC<Props> = ({ className }) => {
  return (
    <svg
      className={className}
      width="46"
      height="32"
      viewBox="0 0 46 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.875 15.9999H43.125"
        stroke="#090909"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.875 27.9999H43.125"
        stroke="#090909"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.875 3.99994H43.125"
        stroke="#090909"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

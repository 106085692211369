import React from 'react';

interface Props {
  className?: string;
}

export const IconRequest: React.FC<Props> = ({ className }) => {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.900146"
        y="4.5"
        width="22.2"
        height="15"
        rx="1.5"
        stroke="#828282"
        strokeWidth="1.8"
      />
      <path
        d="M22.5 5.25L12.8719 12.1272C12.3503 12.4998 11.6497 12.4998 11.1281 12.1272L1.5 5.25"
        stroke="#828282"
        strokeWidth="1.5"
      />
    </svg>
  );
};

import React, { lazy, useEffect } from 'react';
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom';

import { NotFoundPage } from '@/components/NotFoundPage';

const LoginPage = lazy(() => import('@/routes/auth/Login'));
const SignUpPage = lazy(() => import('@/routes/auth/Signup'));
const ForgotPassword = lazy(() => import('@/routes/auth/ForgotPassword'));
const ForgotPasswordByPhone = lazy(
  () => import('@/routes/auth/ForgotPasswordByPhone'),
);
const RecoverPasswordPage = lazy(() => import('@/routes/auth/RecoverPassword'));
const RecoverPasswordByPhone = lazy(
  () => import('@/routes/auth/RecoverPasswordByPhone'),
);

const MeterReadingWithoutRegisterPage = lazy(
  () => import('@/routes/auth/MeterReadingWithoutRegister'),
);
const PaymentWithoutRegisterPage = lazy(
  () => import('@/routes/auth/PaymentWithoutRegister'),
);
const InfoPage = lazy(() => import('@/routes/auth/InfoPage'));
const SignupConfirmPage = lazy(() => import('@/routes/auth/SignupConfirm'));
const ReceiptLinkExternalPage = lazy(
  () => import('@/routes/common/ReceiptLink/External'),
);

const AuthRoute: React.FC = () => {
  const accessToken = localStorage.getItem('accessToken');
  const navigate = useNavigate();
  const { pathname, search } = useLocation();

  const isAllowPage =
    pathname === '/auth/recover-password' && search.includes('token');

  useEffect(() => {
    if (accessToken && !isAllowPage) {
      navigate('/');
    }
  }, [accessToken, isAllowPage, navigate]);

  return (
    <Routes>
      <Route path="/" element={<LoginPage />} />
      <Route path="signup" element={<SignUpPage />} />
      <Route path="info" element={<InfoPage />} />
      <Route path="signup-confirm" element={<SignupConfirmPage />} />
      <Route path="forgot-password" element={<ForgotPassword />} />
      <Route path="recover-password" element={<RecoverPasswordPage />} />
      <Route
        path="recover-password-by-phone"
        element={<RecoverPasswordByPhone />}
      />

      <Route
        path="forgot-password-by-phone"
        element={
          process.env.REACT_APP_SMS_SERVICE_AVAILABLE === 'true' ? (
            <ForgotPasswordByPhone />
          ) : (
            <Navigate to="/forgot-password" />
          )
        }
      />

      <Route path="payment" element={<PaymentWithoutRegisterPage />} />
      <Route
        path="meter-reading"
        element={<MeterReadingWithoutRegisterPage />}
      />
      <Route path="receipt-link" element={<ReceiptLinkExternalPage />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};

export default AuthRoute;

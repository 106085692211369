import { createApi } from '@reduxjs/toolkit/query/react';

import {
  IUpdateOperatorInfoRequest,
  IUpdateOperatorInfoResponse,
} from '@/interfaces/user';

import { baseQueryWithReauth } from '../baseQuery';

export const operatorInfoApi = createApi({
  reducerPath: 'operatorInfoApi',
  baseQuery: baseQueryWithReauth,
  tagTypes: ['Operator'],
  endpoints: (builder) => ({
    updateOperatorInfo: builder.mutation<
      IUpdateOperatorInfoResponse,
      IUpdateOperatorInfoRequest
    >({
      query: (data) => ({
        url: `user/update/${data.operatorId}`,
        method: 'POST',
        body: {
          name: data.name,
          phone: data.phone,
        },
      }),
    }),
  }),
});

export const { useUpdateOperatorInfoMutation } = operatorInfoApi;

import React from 'react';
import { useSelector } from 'react-redux';

import Loading from '@/components/Loading';
import { EUserRole } from '@/consts';
import { RootState } from '@/redux';

import AdminRouter from './AdminRouter';
import CustomerRouter from './CustomerRouter';

const UserRouter: React.FC = () => {
  const {
    user: { data },
  } = useSelector((state: RootState) => state);

  if (data?.user_role !== EUserRole.Customer) {
    return <AdminRouter />;
  }

  if (data?.user_role === EUserRole.Customer) {
    return <CustomerRouter />;
  }

  return <Loading />;
};

export default UserRouter;

import { fetchBaseQuery } from '@reduxjs/toolkit/dist/query';
import type {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query';

import { ILoginResponse } from '@/interfaces/user';

import { logout } from '../slices/user';

export const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_HOST,
  prepareHeaders(headers) {
    const token = localStorage.getItem('accessToken');

    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }
    return headers;
  },
});

export const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);

  if (result?.meta?.response?.status === 401) {
    const token = localStorage.getItem('accessToken');

    if (!token) {
      return result;
    }

    // try to get a new token
    const refreshResult = await baseQuery(
      {
        url: 'auth/refresh',
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
      api,
      extraOptions,
    );

    if (
      refreshResult.error?.status === 400 ||
      refreshResult.meta?.response?.status === 500 ||
      refreshResult.error?.status === 401 ||
      refreshResult.meta?.response?.status === 401
    ) {
      localStorage.removeItem('accessToken');
      window.location.replace('/auth');
    }

    const data = refreshResult.data as ILoginResponse;

    if (data.access_token) {
      localStorage.setItem('accessToken', data.access_token);
      result = await baseQuery(args, api, extraOptions);
    } else {
      localStorage.removeItem('accessToken');
      api.dispatch(logout());
      (window as Window).location.href = '/';
    }
  }
  return result;
};

import Modal from '@mui/material/Modal';
import { clsx } from 'clsx';
import * as React from 'react';

import { Htag } from '@/components/Htag';
import { IconClose } from '@/components/Icons/IconClose';
import { useCssQuery } from '@/providers/MediaQuery';

import styles from './CallModal.module.css';
import { CallForm } from './Form';

interface ICallModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const CallModal: React.FC<ICallModalProps> = (props) => {
  const { isOpen, onClose } = props;
  const { isTabled, isMobile } = useCssQuery();

  return (
    <div>
      <Modal open={isOpen}>
        <div
          className={clsx(styles.root, {
            [styles.isTabled]: isTabled,
            [styles.isMobile]: isMobile,
          })}
        >
          <div className={styles.closeIconWrapper} onClick={onClose}>
            <IconClose className={styles.closeIcon} />
          </div>

          <div className={styles.content}>
            <Htag tag="h2">Заказать звонок</Htag>
            <CallForm onClose={onClose} />
          </div>
        </div>
      </Modal>
    </div>
  );
};

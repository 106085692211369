import { yupResolver } from '@hookform/resolvers/yup';
import * as dateFns from 'date-fns';
import { useState } from 'react';
import { Resolver, useForm } from 'react-hook-form';

import { useSnackbarSuccess } from '@/hooks/useSnackbatSuccess';
import { EServerMessageType, IServerMessage } from '@/interfaces/common';
import { ICreateNotoficationRequest } from '@/interfaces/notification';
import { useCreateNotificationMutation } from '@/redux/api/operator/operatorNotificationApi';

import {
  createNotificationFormShema,
  ICreateNotoficationForm,
} from './validation';

export default function useCreateNotification() {
  const [createNotification, { isLoading }] = useCreateNotificationMutation();
  const [serverMessage, setServerMessage] = useState<IServerMessage | null>(
    null,
  );
  const onSuccess = useSnackbarSuccess();

  const form = useForm<ICreateNotoficationForm>({
    defaultValues: { for_all: 1 },
    reValidateMode: 'onSubmit',
    resolver: yupResolver(
      createNotificationFormShema,
    ) as Resolver<ICreateNotoficationForm>,
  });

  const onSubmit = (data: ICreateNotoficationForm) => {
    const formData: ICreateNotoficationRequest = {
      for_all: data.for_all,
      users: data.personalAccounts,
      color: data.color,
      text: data.text,
      title: data.title,
      due_date: dateFns.format(data.due_date, 'yyyy-MM-dd'),
    };

    createNotification({ ...formData })
      .unwrap()
      .then((response) => {
        onSuccess(response.message);
        form.reset();
        form.setValue('for_all', 1);
        form.setValue('color', 1);
      })
      .catch((error) => {
        setServerMessage({
          type: EServerMessageType.Error,
          text: error.data.messages,
        });
      });
  };

  return {
    ...form,
    handleSubmit: form.handleSubmit(onSubmit),
    isLoading,
    serverMessage,
  };
}

import { clsx } from 'clsx';
import React from 'react';
import { useSelector } from 'react-redux';
import useToggle from 'react-use/lib/useToggle';

import { CallButton } from '@/components/CallButton';
import { CallModal } from '@/components/CallModal';
import { EUserRole } from '@/consts';
import { useCssQuery } from '@/providers/MediaQuery';
import { RootState } from '@/redux';

import { Navigation } from './Navigation';
import { NotificationCreatingModal } from './NotificationCreatingModal';
import styles from './Sidebar.module.css';

interface ISidebarProps {
  toggleShowMobileMenu?: () => void;
}

export const Sidebar: React.FC<ISidebarProps> = (props) => {
  const { toggleShowMobileMenu } = props;
  const { isDesktop, isTabled, isLaptop, isMobile } = useCssQuery();
  const { user } = useSelector((state: RootState) => state);

  const [isOpenNotificationModal, toggleOpenNotificationModal] =
    useToggle(false);
  const [isOpenCallModal, toggleOpenCallModal] = useToggle(false);

  return (
    <>
      <div
        className={clsx(styles.root, {
          [styles.large]: !isDesktop,
          [styles.isMobile]: isMobile,
          [styles.isTabled]: isTabled,
          [styles.isLaptop]: isLaptop,
        })}
      >
        <Navigation toggleShowMobileMenu={toggleShowMobileMenu} />

        {user.data?.user_role === EUserRole.Customer ? (
          <CallButton
            className={styles.callButton}
            onClick={toggleOpenCallModal}
          />
        ) : (
          <button
            className={styles.creatingNotificationButton}
            onClick={toggleOpenNotificationModal}
          >
            Создать уведомление
          </button>
        )}
      </div>

      <NotificationCreatingModal
        isOpen={isOpenNotificationModal}
        onClose={toggleOpenNotificationModal}
      />

      <CallModal isOpen={isOpenCallModal} onClose={toggleOpenCallModal} />
    </>
  );
};

import { createApi } from '@reduxjs/toolkit/query/react';

import { IAgreementData } from '@/interfaces/agreement';

import { baseQueryWithReauth } from '../baseQuery';

export const agreementApi = createApi({
  reducerPath: 'agreementApi',
  baseQuery: baseQueryWithReauth,
  tagTypes: ['Agreement'],
  endpoints: (builder) => ({
    getAgreementText: builder.query<IAgreementData, void>({
      query: () => ({
        url: `pages/get/1`,
        method: 'GET',
      }),
      providesTags: ['Agreement'],
    }),
    saveAgreementText: builder.mutation<void, { content: string }>({
      query: (data) => ({
        url: `pages/save/1`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Agreement'],
    }),
  }),
});

export const { useGetAgreementTextQuery, useSaveAgreementTextMutation } =
  agreementApi;

import React from 'react';
import { useSelector } from 'react-redux';

import { IconAccruals } from '@/components/Icons/sidebar/IconAccruals';
import { IconHeadphones } from '@/components/Icons/sidebar/IconHeadphones';
import { IconPeople } from '@/components/Icons/sidebar/IconPeople';
import { IconRequest } from '@/components/Icons/sidebar/IconRequest';
import { IconSettings } from '@/components/Icons/sidebar/IconSettings';
import { IconStatistic } from '@/components/Icons/sidebar/IconStatistic';
import { EUserRole } from '@/consts';
import { RootState } from '@/redux';

import { NavigationLink } from '../Link';
import styles from './Admin.module.css';
import { NavigationAdminRequestsLinks } from './RequestLinks';

interface INavigationAdminprops {
  toggleShowMobileMenu?: () => void;
}

export const NavigationAdmin: React.FC<INavigationAdminprops> = (props) => {
  const { toggleShowMobileMenu } = props;
  const { user } = useSelector((state: RootState) => state);

  return (
    <div className={styles.root}>
      <NavigationLink
        title="Заявки"
        to="/"
        icon={<IconRequest />}
        nestedItems={
          <NavigationAdminRequestsLinks
            toggleShowMobileMenu={toggleShowMobileMenu}
          />
        }
        toggleShowMobileMenu={toggleShowMobileMenu}
      />

      <NavigationLink
        title="Пользователи"
        to="/counterparties"
        icon={<IconPeople />}
        toggleShowMobileMenu={toggleShowMobileMenu}
      />

      {user.data?.user_role === EUserRole.Admin && (
        <NavigationLink
          title="Операторы"
          to="/operators"
          icon={<IconHeadphones />}
          toggleShowMobileMenu={toggleShowMobileMenu}
        />
      )}

      <NavigationLink
        title="Статистика "
        to="/statistics"
        icon={<IconStatistic />}
        toggleShowMobileMenu={toggleShowMobileMenu}
      />
      <NavigationLink
        title="Настройки "
        to="/settings"
        icon={<IconSettings />}
        toggleShowMobileMenu={toggleShowMobileMenu}
      />
      <NavigationLink
        title="Соглашение "
        to="/agreement"
        icon={<IconAccruals />}
        toggleShowMobileMenu={toggleShowMobileMenu}
      />
    </div>
  );
};

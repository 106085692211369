import { clsx } from 'clsx';
import { PopupState } from 'material-ui-popup-state/hooks';
import React from 'react';

import { IconAttention } from '@/components/Icons/IconAttention';
import { INotification } from '@/interfaces/notification';
import { useCssQuery } from '@/providers/MediaQuery';

import styles from './NotificationMessageItem.module.css';

interface INotificationMessageItemProps {
  popupState: PopupState;
  data: INotification;
}

export const NotificationMessageItem: React.FC<
  INotificationMessageItemProps
> = (props) => {
  const { isMobile } = useCssQuery();
  const { popupState, data } = props;

  return (
    <div
      className={clsx(styles.root, {
        [styles.isMobile]: isMobile,
      })}
    >
      <IconAttention className={styles.icon} />

      <div className={styles.messageBody}>
        <div className={styles.message}>{data.title}</div>
      </div>

      <div className={styles.date}>{String(data.created_at)}</div>
    </div>
  );
};

import React, { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';

const OperatorsPage = lazy(() => import('@/routes/admin/Operators'));
const OperatorsDetailsPage = lazy(
  () => import('@/routes/admin/Operators/Details'),
);

const OperatorsRoute: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<OperatorsPage />} />
      <Route path="details/:operatorId" element={<OperatorsDetailsPage />} />
    </Routes>
  );
};

export default OperatorsRoute;

import { configureStore } from '@reduxjs/toolkit';

import rootReducers, { apiMiddleware } from './reducers';
import { rtkQueryErrorLogger } from './rtkQueryErrorLogger';

export const store = configureStore({
  reducer: rootReducers,
  middleware: (getDefaultMiddleware) => [
    ...getDefaultMiddleware({ serializableCheck: false })
      .concat(apiMiddleware)
      .concat(rtkQueryErrorLogger),
  ],
});

export type RootState = ReturnType<typeof store.getState>;

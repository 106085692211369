export enum EServerMessageType {
  Success,
  Error,
}

export interface IServerMessage {
  type: EServerMessageType;
  text: string;
}

export interface IUserPersonalAcoountDetailed {
  acc_type_id: number;
  building_id: number;
  code: string;
  consent_date: Date;
  consent_status: number;
  created_at: Date;
  flat: number;
  house_space: string;
  id: number;
  ls_change_date: string;
  name: string;
  organisation_id: number;
  pa_date: Date;
  people: number;
  personal_account: number;
  room: number;
  updated_at: Date;
}

export interface IUserPersonalAccount {
  id: number;
  account_number: number;
  address: string;
  locality: string;
  street: string;
  house: number;
  slash: string;
  letter: string;
  debt: string;
}

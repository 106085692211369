import { clsx } from 'clsx';
import React from 'react';

import { IconBack } from '@/components/Icons/IconBack';
import { useCssQuery } from '@/providers/MediaQuery';
import { useSwitchAccount } from '@/providers/SwitchAccount';

import styles from './ReturnButton.module.css';

interface IReturnIntoOperatorButtonProps {
  fullWidth?: boolean;
}

export const ReturnIntoOperatorButton: React.FC<
  IReturnIntoOperatorButtonProps
> = (props) => {
  const { fullWidth } = props;
  const { isMobile } = useCssQuery();
  const { onReturnInToOperator } = useSwitchAccount();

  return (
    <button
      className={clsx(styles.root, {
        [styles.isMobile]: isMobile,
        [styles.fullWidth]: fullWidth,
      })}
      onClick={onReturnInToOperator}
    >
      <IconBack className={styles.returnButtonIcon} /> Вернуться
    </button>
  );
};

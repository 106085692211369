import React, { lazy, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Route, Routes } from 'react-router-dom';

import { UnavailablePersonalAccount } from '@/components/UnavailablePersonalAccount';
import { RootState } from '@/redux';

const PersonalAccountsPage = lazy(
  () => import('@/routes//customer/PersonalAccounts'),
);
const PersonalAccountAdding = lazy(
  () => import('@/routes//customer/PersonalAccounts/Adding'),
);
const PersonalAccountDetails = lazy(
  () => import('@/routes//customer/PersonalAccounts/Details'),
);

const PersonalAccountsRoute: React.FC = () => {
  const {
    user: { data },
  } = useSelector((state: RootState) => state);

  const availablePersonalAccount = useMemo(() => {
    return data?.current_account.id;
  }, [data?.current_account]);

  return (
    <Routes>
      <Route
        path="/"
        element={
          availablePersonalAccount ? (
            <PersonalAccountsPage />
          ) : (
            <UnavailablePersonalAccount />
          )
        }
      />
      <Route
        path="details/:personalAccountId"
        element={
          availablePersonalAccount ? (
            <PersonalAccountDetails />
          ) : (
            <UnavailablePersonalAccount />
          )
        }
      />
      <Route path="add" element={<PersonalAccountAdding />} />
    </Routes>
  );
};

export default PersonalAccountsRoute;

import React from 'react';

import styles from './Error.module.css';

interface IFormItemErrorProps {
  errorMessage?: string;
}

export const FormItemError: React.FC<IFormItemErrorProps> = (props) => {
  const { errorMessage } = props;

  if (!errorMessage) {
    return null;
  }

  return <span className={styles.root}>{errorMessage}</span>;
};

import { clsx } from 'clsx';
import React from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';

import { RootState } from '@/redux';

import styles from './RequestLinks.module.css';

interface INavigationAdminRequestsLinksProps {
  toggleShowMobileMenu?: () => void;
}

export const NavigationAdminRequestsLinks: React.FC<
  INavigationAdminRequestsLinksProps
> = (props) => {
  const { toggleShowMobileMenu } = props;

  const {
    requests: { freeRequestsCounter, inWorkRequestsCounter },
  } = useSelector((state: RootState) => state);

  const handleClick = () => {
    toggleShowMobileMenu?.();
  };

  return (
    <div className={styles.root}>
      <NavLink className={clsx(styles.link)} to="free" onClick={handleClick}>
        <div className={styles.title}>Свободные</div>
        <div className={styles.counter}>{freeRequestsCounter}</div>
      </NavLink>

      <NavLink
        className={clsx(styles.link)}
        to="/in-work"
        onClick={handleClick}
      >
        <div className={styles.title}>В работе</div>
        <div className={styles.counter}>{inWorkRequestsCounter}</div>
      </NavLink>

      <NavLink
        className={clsx(styles.link)}
        to="/archive"
        onClick={handleClick}
      >
        <div className={styles.title}>Архив</div>
      </NavLink>
    </div>
  );
};

import React, { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
const TermsOfConditionsPage = lazy(
  () => import('@/routes/auth/TermsOfConditions'),
);
import { MainLayout } from '@/common/ui/MainLayout';

import AuthRoute from './auth/AuthRouter';

const RootRouter: React.FC = () => {
  return (
    <Routes>
      <Route path="/auth/*" element={<AuthRoute />} />
      <Route path="/auth/*" element={<AuthRoute />} />
      <Route path="/terms-of-conditions" element={<TermsOfConditionsPage />} />
      <Route path="*" element={<MainLayout />} />
    </Routes>
  );
};

export default RootRouter;

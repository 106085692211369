import { createApi } from '@reduxjs/toolkit/query/react';

import { IPaymentUrlRequest, IPreparePaymentData } from '@/interfaces/payment';

import { baseQueryWithReauth } from './baseQuery';

export const paymentApi = createApi({
  reducerPath: 'paymentApi',
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getPreparePaymentData: builder.query<IPreparePaymentData, void>({
      query: () => ({
        url: `pay/get-data`,
        method: 'GET',
      }),
    }),
    getPaymentUrl: builder.mutation<{ url: string }, IPaymentUrlRequest>({
      query: (data) => ({
        url: `pay/get-url`,
        method: 'POST',
        body: data,
      }),
    }),
  }),
});

export const { useGetPreparePaymentDataQuery, useGetPaymentUrlMutation } =
  paymentApi;

import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import { INotification } from '@/interfaces/notification';

export interface NotificationsState {
  list: Array<INotification>;
  globalList: Array<INotification>;
  per_page: number;
  total: number;
  isShowAllMessages: boolean;
  countUnreadMessages: number;
}

const initialState: NotificationsState = {
  list: [],
  globalList: [],
  per_page: 10,
  total: 0,
  isShowAllMessages: false,
  countUnreadMessages: 0,
};

export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    updateNotificationList: (
      state,
      action: PayloadAction<{
        list: Array<INotification>;
        total: number;
        isShowAllMessages: boolean;
        countUnreadMessages: number;
      }>,
    ) => {
      state.list = [...action.payload.list];
      state.total = action.payload.total;
      state.isShowAllMessages = action.payload.isShowAllMessages;
      state.countUnreadMessages = action.payload.countUnreadMessages;
    },
    updateGlobalNotificationList: (
      state,
      action: PayloadAction<{
        globalList: Array<INotification>;
      }>,
    ) => {
      state.globalList = [...action.payload.globalList];
    },
  },
});

export const { updateNotificationList, updateGlobalNotificationList } =
  notificationsSlice.actions;

export default notificationsSlice.reducer;

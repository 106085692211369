import React from 'react';

interface Props {
  className?: string;
}

export const IconSnackbarSuccess: React.FC<Props> = ({ className }) => {
  return (
    <svg
      className={className}
      width="29"
      height="30"
      viewBox="0 0 29 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M27.822 15.0001C27.822 7.64263 21.8576 1.67822 14.5001 1.67822C7.14263 1.67822 1.17822 7.64263 1.17822 15.0001C1.17822 22.3576 7.14263 28.322 14.5001 28.322C21.8576 28.322 27.822 22.3576 27.822 15.0001Z"
        stroke="#005323"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M19.3996 11.7959L12.9914 18.2041L9.78735 15"
        stroke="#005323"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

import { clsx } from 'clsx';
import React, { PropsWithChildren } from 'react';
import { useSelector } from 'react-redux';

import { EUserRole } from '@/consts';
import { useCssQuery } from '@/providers/MediaQuery';
import { RootState } from '@/redux';

import { Header } from './Header';
import { NotificationGlobalList } from './NotificationGlobalList';
import { Sidebar } from './Sidebar';
import styles from './UserLayout.module.css';

export const UserLayout: React.FC<PropsWithChildren> = ({ children }) => {
  const { isDesktop, isMobile } = useCssQuery();
  const { user } = useSelector((state: RootState) => state);

  return (
    <div
      className={clsx(styles.root, {
        [styles.small]: !isDesktop,
      })}
    >
      <Header />

      <main>
        {isDesktop && <Sidebar />}

        <div
          className={clsx(styles.content, {
            [styles.isMobile]: isMobile,
          })}
        >
          {user.data?.user_role === EUserRole.Customer && (
            <NotificationGlobalList />
          )}

          {children}
        </div>
      </main>
    </div>
  );
};

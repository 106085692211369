import { clsx } from 'clsx';
import React, { ReactNode } from 'react';

import { BackButton } from '../BackButton';
import styles from './Htag.module.css';

interface IHtagProps {
  tag: 'h1' | 'h2' | 'h3';
  color?: 'blue';
  withBackButton?: boolean;
  className?: string;
  children: ReactNode;
}

export const Htag: React.FC<IHtagProps> = (props) => {
  const { tag, color, withBackButton = false, className, children } = props;

  return (
    <div
      className={clsx(className, {
        [styles.h1]: tag === 'h1',
        [styles.h2]: tag === 'h2',
        [styles.h3]: tag === 'h3',
        [styles.blueColor]: color === 'blue',
      })}
    >
      <div className={styles.wrapper}>
        {withBackButton && <BackButton />}
        {children}
      </div>
    </div>
  );
};

import { createApi } from '@reduxjs/toolkit/query/react';

import { ICallRequestPayload } from '@/interfaces/callRequest';

import { baseQueryWithReauth } from './baseQuery';

export const callRequestApi = createApi({
  reducerPath: 'callRequestApi',
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    sendCallRequest: builder.mutation<{ message: string }, ICallRequestPayload>(
      {
        query: (data) => ({
          url: 'call-request',
          method: 'POST',
          body: data,
        }),
      },
    ),
  }),
});

export const { useSendCallRequestMutation } = callRequestApi;

import { clsx } from 'clsx';
import React, { ReactNode } from 'react';
import { NavLink } from 'react-router-dom';

import styles from './Link.module.css';

interface INavigationItemProps {
  title: string;
  to: string;
  icon: ReactNode;
  nestedItems?: ReactNode;
  toggleShowMobileMenu?: () => void;
}

export const NavigationLink: React.FC<INavigationItemProps> = (props) => {
  const { title, to, icon, toggleShowMobileMenu, nestedItems } = props;

  const handleClick = (e: React.MouseEvent) => {
    if (nestedItems) {
      e.preventDefault();
    } else {
      toggleShowMobileMenu?.();
    }
  };

  return (
    <>
      <NavLink
        className={clsx(styles.root, {
          [styles.disabled]: nestedItems,
        })}
        to={to}
        onClick={handleClick}
      >
        <div className={styles.icon}>{icon}</div>
        <div className={styles.title}>{title}</div>
      </NavLink>

      <div className={styles.nestedItems}>{nestedItems}</div>
    </>
  );
};

import CircularProgress from '@mui/material/CircularProgress';
import Fade from '@mui/material/Fade';
import { clsx } from 'clsx';
import React, { FC, useEffect } from 'react';
import useToggle from 'react-use/lib/useToggle';

import styles from './Loading.module.css';

interface ILoadingProps {
  title?: string;
  className?: string;
  circularColor?: string;
  size?: number | string;
  variant?: 'global' | 'internal';
  maxHeight?: boolean;
}

const Loading: FC<ILoadingProps> = (props) => {
  const {
    title,
    className,
    circularColor,
    size,
    variant = 'global',
    maxHeight,
  } = props;
  const [mounted, toggleMounted] = useToggle(false);

  useEffect(() => {
    toggleMounted();
    return () => toggleMounted();
  }, [toggleMounted]);

  return (
    <div
      className={clsx(styles.root, className, {
        [styles.global]: variant === 'global',
        [styles.internal]: variant === 'internal',
        [styles.maxHeight]: maxHeight,
      })}
    >
      <Fade in={mounted} style={{ transitionDelay: '500ms' }}>
        <div className={styles.content}>
          <CircularProgress
            className={clsx(styles.circularProgress, circularColor)}
            size={size}
          />

          {title && <p className={styles.title}>{title}</p>}
        </div>
      </Fade>
    </div>
  );
};

export default Loading;

import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { useSnackbarError } from '@/hooks/useSnackbackError';
import { useLoginIntoCustomerMutation } from '@/redux/api/userApi';
import { clearUserInfo } from '@/redux/slices/user';

export const useLoginIntoCustomer = (): ((customerId: number) => void) => {
  const [loginIntoCustomer] = useLoginIntoCustomerMutation();
  const displayError = useSnackbarError();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onLoginInToCustomer = (customerId: number) => {
    loginIntoCustomer({ customerId })
      .unwrap()
      .then((res) => {
        dispatch(clearUserInfo());
        localStorage.setItem('accessToken', res.access_token);

        navigate('/');
      })
      .catch(() => {
        displayError('Ошибка переключения аккаунтов');
      });
  };

  return onLoginInToCustomer;
};

import { combineReducers } from 'redux';

import { accrualsApi } from './api/accrualsApi';
import { operatorsApi } from './api/admin/operatorsApi';
import { authApi } from './api/authApi';
import { callRequestApi } from './api/callRequestApi';
import { checkPersonalAccountApi } from './api/checkPersonalAccountApi';
import { externalLinkApi } from './api/externalLink';
import { filesApi } from './api/filesApi';
import { meterReadingsApi } from './api/meterReadingsApi';
import { meterWithoutRegisterApi } from './api/meterWithoutRegisterApi';
import { notificationsApi } from './api/notificationsApi';
import { agreementApi } from './api/operator/agreementApi';
import { customersApi } from './api/operator/customersApi';
import { operatorInfoApi } from './api/operator/operatorInfoApi';
import { operatorNotificationApi } from './api/operator/operatorNotificationApi';
import { operatorRequestsApi } from './api/operator/operatorRequestsApi';
import { statisticsApi } from './api/operator/statisticsApi';
import { organizationsApi } from './api/organizationApi';
import { paymentApi } from './api/paymentApi';
import { paymentHistoryApi } from './api/paymentHistoryApi';
import { paymentWithoutRegisterApi } from './api/paymentWithoutRegisterApi';
import { personalAccountsApi } from './api/personalAccountsApi';
import { requestsApi } from './api/requestsApi';
import { userApi } from './api/userApi';
import globalError from './slices/error';
import notifications from './slices/notifications';
import receiptLink from './slices/receiptLink';
import requests from './slices/requests';
import user from './slices/user';

const reducers = combineReducers({
  user,
  notifications,
  requests,
  globalError,
  receiptLink,
  [authApi.reducerPath]: authApi.reducer,
  [userApi.reducerPath]: userApi.reducer,
  [personalAccountsApi.reducerPath]: personalAccountsApi.reducer,
  [meterReadingsApi.reducerPath]: meterReadingsApi.reducer,
  [organizationsApi.reducerPath]: organizationsApi.reducer,
  [accrualsApi.reducerPath]: accrualsApi.reducer,
  [notificationsApi.reducerPath]: notificationsApi.reducer,
  [requestsApi.reducerPath]: requestsApi.reducer,
  [filesApi.reducerPath]: filesApi.reducer,
  [customersApi.reducerPath]: customersApi.reducer,
  [statisticsApi.reducerPath]: statisticsApi.reducer,
  [operatorRequestsApi.reducerPath]: operatorRequestsApi.reducer,
  [operatorInfoApi.reducerPath]: operatorInfoApi.reducer,
  [operatorNotificationApi.reducerPath]: operatorNotificationApi.reducer,
  [meterWithoutRegisterApi.reducerPath]: meterWithoutRegisterApi.reducer,
  [checkPersonalAccountApi.reducerPath]: checkPersonalAccountApi.reducer,
  [paymentHistoryApi.reducerPath]: paymentHistoryApi.reducer,
  [operatorsApi.reducerPath]: operatorsApi.reducer,
  [agreementApi.reducerPath]: agreementApi.reducer,
  [callRequestApi.reducerPath]: callRequestApi.reducer,
  [paymentApi.reducerPath]: paymentApi.reducer,
  [paymentWithoutRegisterApi.reducerPath]: paymentWithoutRegisterApi.reducer,
  [externalLinkApi.reducerPath]: externalLinkApi.reducer,
});

export const apiMiddleware = [
  authApi.middleware,
  userApi.middleware,
  personalAccountsApi.middleware,
  meterReadingsApi.middleware,
  organizationsApi.middleware,
  accrualsApi.middleware,
  notificationsApi.middleware,
  requestsApi.middleware,
  filesApi.middleware,
  customersApi.middleware,
  statisticsApi.middleware,
  operatorRequestsApi.middleware,
  operatorInfoApi.middleware,
  operatorNotificationApi.middleware,
  meterWithoutRegisterApi.middleware,
  checkPersonalAccountApi.middleware,
  paymentHistoryApi.middleware,
  operatorsApi.middleware,
  agreementApi.middleware,
  callRequestApi.middleware,
  paymentApi.middleware,
  paymentWithoutRegisterApi.middleware,
  externalLinkApi.middleware,
];

export default reducers;

import { clsx } from 'clsx';
import React, { useEffect } from 'react';
import { Controller } from 'react-hook-form';
import { PatternFormat } from 'react-number-format';
import { useSelector } from 'react-redux';
import useToggle from 'react-use/lib/useToggle';

import { FormItem } from '@/components/FormItem';
import { Input } from '@/components/Input';
import { MainButton } from '@/components/MainButton';
import { ServerMessage } from '@/components/ServerMessage';
import { useCssQuery } from '@/providers/MediaQuery';
import { RootState } from '@/redux';

import styles from './Form.module.css';
import useRequestCall from './useRequestCall';

interface ICallFormProps {
  onClose: () => void;
}

export const CallForm: React.FC<ICallFormProps> = (props) => {
  const { onClose } = props;
  const { isTabled, isMobile } = useCssQuery();
  const { user } = useSelector((state: RootState) => state);

  const {
    formState: { errors },
    register,
    handleSubmit,
    control,
    isLoading,
    serverMessage,
    setValue,
  } = useRequestCall(onClose);

  const [onFocus, toggleFocus] = useToggle(false);

  useEffect(() => {
    if (user.data?.current_account.account_number) {
      setValue(
        'personal_account',
        String(user.data?.current_account.account_number),
      );
    }
  }, [setValue, user]);

  return (
    <form
      className={clsx(styles.root, {
        [styles.isTabled]: isTabled,
        [styles.isMobile]: isMobile,
      })}
      onSubmit={handleSubmit}
    >
      <FormItem errorMessage={errors.name?.message}>
        <Input placeholder="ФИО" register={register('name')} />
      </FormItem>

      {!user.data?.current_account.account_number && (
        <FormItem errorMessage={errors.personal_account?.message}>
          <Input
            placeholder="Номер лицевого счета"
            register={register('personal_account')}
          />
        </FormItem>
      )}

      <FormItem errorMessage={errors.phone?.message}>
        <Controller
          control={control}
          name="phone"
          render={({ field: { onChange, name, value } }) => (
            <PatternFormat
              name={name}
              customInput={Input}
              value={value}
              onChange={onChange}
              format="+7 (###) ###-####"
              mask={'_'}
              placeholder="+7()"
              autoComplete="nofill"
              error={!!errors.phone?.message}
              onFocus={toggleFocus}
              onBlur={toggleFocus}
              allowEmptyFormatting={onFocus}
            />
          )}
        />
      </FormItem>

      <MainButton fullWidth size="large" isLoading={isLoading}>
        Заказать звонок
      </MainButton>

      <ServerMessage message={serverMessage} />
    </form>
  );
};

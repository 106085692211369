import { useState } from 'react';

import { useLazySearchPaQuery } from '@/redux/api/operator/operatorNotificationApi';

import { debounce } from './debounce';

export interface IPaSearch {
  id: number;
  label: string;
}

export const usePaSearch = () => {
  const [searchPa] = useLazySearchPaQuery();
  const [selectedUsers, setSelectedUsers] = useState<Array<IPaSearch>>([]);

  const getCustomerValue = (searchParams: string) => {
    return searchPa({ search: searchParams });
  };

  const searchOptions = (
    searchValue: string,
    callback: (options: IPaSearch[]) => void,
  ) => {
    getCustomerValue(searchValue).then((res) => {
      if (res.data) {
        const customerData: IPaSearch[] = res.data
          .filter((pa) => !selectedUsers.find((user) => user.id === pa.id))
          .map((pa) => {
            const customerOption: IPaSearch = {
              id: pa.id,
              label: pa.personal_account,
            };
            return customerOption;
          });

        callback(customerData);
      }
    });
  };

  const debounceSearchOptions = debounce(searchOptions, 300);

  return {
    selectedUsers,
    setSelectedUsers,
    debounceSearchOptions,
  };
};

import { createApi } from '@reduxjs/toolkit/query/react';

import {
  IInviteOperatorRequest,
  IInviteOperatorResponse,
  IOperatorDetailes,
  IOperatorList,
  IOperatorListRequest,
  IOperatorRequestList,
} from '@/interfaces/admin';

import { baseQueryWithReauth } from '../baseQuery';

export const operatorsApi = createApi({
  reducerPath: 'operatorsApi',
  baseQuery: baseQueryWithReauth,
  tagTypes: ['Operators'],
  endpoints: (builder) => ({
    inviteOperator: builder.mutation<
      IInviteOperatorResponse,
      IInviteOperatorRequest
    >({
      query: (data) => ({
        url: 'user/create',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Operators'],
    }),
    getAllOperators: builder.query<IOperatorList, IOperatorListRequest>({
      query: (data) => ({
        url: `user/getAllOperators?per_page=10&page=${data.page}${
          data ? `&search=${data.search}` : ''
        }`,
        method: 'GET',
      }),
      providesTags: ['Operators'],
    }),
    getOperatorDetails: builder.query<
      IOperatorDetailes,
      { operatorId: string }
    >({
      query: (data) => ({
        url: `user/getOperatorInfo/${data.operatorId}`,
        method: 'GET',
      }),
    }),
    getOperatorRequests: builder.query<
      IOperatorRequestList,
      { operatorId: string; requestType: number; page: number }
    >({
      query: (data) => ({
        url: `user/getOperatorInfo/${data.operatorId}/requests?type=${data.requestType}&per_page=4&page=${data.page}`,
        method: 'GET',
      }),
    }),
    deleteOperator: builder.mutation<
      { message: string },
      { operatorId: string }
    >({
      query: (data) => ({
        url: `user/delete/${data.operatorId}`,
        method: 'DELETE',
        body: data,
      }),
      invalidatesTags: ['Operators'],
    }),
  }),
});

export const {
  useInviteOperatorMutation,
  useGetAllOperatorsQuery,
  useGetOperatorDetailsQuery,
  useGetOperatorRequestsQuery,
  useDeleteOperatorMutation,
} = operatorsApi;

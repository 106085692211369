import React, { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';

const CounterpartiesPage = lazy(() => import('@/routes/admin/Counterparties'));
const CounterpartiesDetailsPage = lazy(
  () => import('@/routes/admin/Counterparties/Details'),
);

const CounterpartiesRoute: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<CounterpartiesPage />} />
      <Route
        path="details/:personalAccountId"
        element={<CounterpartiesDetailsPage />}
      />
    </Routes>
  );
};

export default CounterpartiesRoute;
